<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="3">
          <v-toolbar color="primary" flat>
            <v-text-field
              v-model="filter.query"
              :prepend-inner-icon="'mdi-filter-variant'"
              append-icon="mdi-magnify"
              clearable
              hide-details
              placeholder="Aa..."
              solo
              text
              @keyup.enter="fetchRecords(filter)"
              @click:clear="fetchRecords(filter)"
            />
            <v-btn
              icon
              @click="handleRefreshItem"
            >
              <v-icon color="white">mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon @click="handleCreateItem">
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider/>
          <v-card-text>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
            >
              <v-card>
                <v-card-title
                  class="subtitle-1"
                >
                  ¿Estás seguro de que quieres eliminar este usuario?
                </v-card-title>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                  >
                    OK
                  </v-btn>
                  <v-spacer/>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-data-table
              :footer-props="{
                  showFirstLastPage: true,
                  'items-per-page-options': [5, 10, 15, 50],
                }"
              :headers="headers"
              :items="items"
              :loading="loadingItems"
              :options.sync="options"
              :server-items-length="serverItemsLength"
              dense
              disable-sort
              item-key="id"
            >
              <template v-slot:[`item.picture`]="{ item }">
                <c-avatar
                  :src="item.picture"
                  :username="item.name"
                  class="my-2"
                />
              </template>
              <template v-slot:[`item.phoneNumber`]="{ item }">
                <span v-if="item.phoneNumber">{{ item.phoneNumber }}</span>
                <span v-else>--</span>
              </template>
              <template v-slot:[`item.roles`]="{ item }">
                <div>
                  <v-chip v-for="role in item.roles" :key="role"
                          class="ma-1"
                          color="indigo"
                          text-color="white">
                    <v-icon left>
                      mdi-account-group-outline
                    </v-icon>
                    {{ role }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           class="ma-1"
                           color="light-blue darken-3"
                           depressed
                           fab
                           x-small
                           v-on="on"
                           @click="handleEditItem(item)">
                      <v-icon color="white" small>mdi-pencil</v-icon>
                    </v-btn>

                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           class="ma-1"
                           color="red"
                           depressed
                           fab
                           x-small
                           v-on="on"
                           @click="deleteItem(item)">
                      <v-icon color="white" small>mdi-delete</v-icon>
                    </v-btn>

                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TooltipMixin from "@/mixins/Tooltip";
import CAvatar from "@/components/avatar/CAvatar";

export default {
  components: { CAvatar },
  inject: ["userRepository"],
  mixins: [TooltipMixin],
  data() {
    return {
      search: "",
      loadingItems: false,
      serverItemsLength: 0,
      itemsPerPage: 10,
      dialogDelete: false,
      filter: {
        page: 1,
        query: null,
      },
      headers: [
        {
          text: "Avatar",
          value: "picture",
          width: 90,
        },
        {
          text: "Usuario",
          value: "identification",
          width: 180,
        },
        {
          text: "Nombre y apellidos",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Teléfono",
          value: "phoneNumber",
        },
        {
          text: "Roles",
          value: "roles",
          'max-width': 430,
        },
        {
          text: "Action",
          value: "actions",
          width: 150,
        },
      ],
      items: [],
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        const { page, itemsPerPage } = this.options;
        this.filter.PageSize = itemsPerPage;
        this.filter.page = page;
        this.fetchRecords(this.filter);
      },
      deep: true,
    },
  },
  methods: {
    resetFilter() {
      this.filter = {
        page: 1,
        query: null,
      };
    },
    fetchRecords(query) {
      this.loadingItems = true;
      this.items = [];
      return this.userRepository
        .list(query)
        .then((data) => {
          this.items = data.result;
          this.serverItemsLength = data.pagination.totalItems;
          this.itemsPerPage = data.pagination.itemsPerPage;
          this.loadingItems = false;
        })
        .finally(() => {
          this.loadingItems = false;
        });
    },
    //action
    handleCreateItem() {
      this.$router.push({
        name: `admin.personal.create`,
      });
    },
    handleEditItem({ id }) {
      this.$router.push({
        name: `admin.personal.edit`,
        params: { id: id },
      });
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const item = this.items[this.editedIndex];
      this.userRepository
        .delete(item.id)
        .then(() => {
          const position = this.items.indexOf(item);
          this.items.splice(position, 1);
          this.serverItemsLength--;
          this.editedIndex = -1;
          this.closeDelete();
        })
        .catch(() => {
        });
    },
    handleSubmit() {
    },
    handleRefreshItem() {
      this.fetchRecords(this.filter);
    },
    // filter
    handlePageChanged(page) {
      this.filter.page = page;
      this.filter.t = Date.now();
      this.$router.replace({
        path: this.$route.path,
        query: this.filter,
      });
    },
    handleClear() {
      this.resetFilter();
      this.filter.t = Date.now();
      this.$router.replace({
        path: this.$route.path,
        query: this.filter,
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
  },
};
</script>
