var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-toolbar',{attrs:{"color":"primary","flat":""}},[_c('v-text-field',{attrs:{"prepend-inner-icon":'mdi-filter-variant',"append-icon":"mdi-magnify","clearable":"","hide-details":"","placeholder":"Aa...","solo":"","text":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchRecords(_vm.filter)},"click:clear":function($event){return _vm.fetchRecords(_vm.filter)}},model:{value:(_vm.filter.query),callback:function ($$v) {_vm.$set(_vm.filter, "query", $$v)},expression:"filter.query"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleRefreshItem}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-refresh")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleCreateItem}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(" ¿Estás seguro de que quieres eliminar este usuario? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1),_c('v-data-table',{attrs:{"footer-props":{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15, 50],
              },"headers":_vm.headers,"items":_vm.items,"loading":_vm.loadingItems,"options":_vm.options,"server-items-length":_vm.serverItemsLength,"dense":"","disable-sort":"","item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.picture",fn:function(ref){
              var item = ref.item;
return [_c('c-avatar',{staticClass:"my-2",attrs:{"src":item.picture,"username":item.name}})]}},{key:"item.phoneNumber",fn:function(ref){
              var item = ref.item;
return [(item.phoneNumber)?_c('span',[_vm._v(_vm._s(item.phoneNumber))]):_c('span',[_vm._v("--")])]}},{key:"item.roles",fn:function(ref){
              var item = ref.item;
return [_c('div',_vm._l((item.roles),function(role){return _c('v-chip',{key:role,staticClass:"ma-1",attrs:{"color":"indigo","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-group-outline ")]),_vm._v(" "+_vm._s(role)+" ")],1)}),1)]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"light-blue darken-3","depressed":"","fab":"","x-small":""},on:{"click":function($event){return _vm.handleEditItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"red","depressed":"","fab":"","x-small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }